import type { FC, PropsWithChildren } from 'react';
import React, { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { DEBOUNCE_DELAY } from '../constants/variables';
import { useGetCatalogueStructure } from '../hooks/useGetCatalogueStructure';
import type { PageNumberContextValues } from '../models';
import { getApiInstance } from '../utils/getApiInstance';
import { getPageNumberParamFromUrl, transformPageInput, updatePageNumberUrlParam } from '../utils/pageCounter';

export const PageNumberContext = createContext<PageNumberContextValues | undefined>(undefined);
PageNumberContext.displayName = 'PageNumberContext';

export interface PageNumberProviderProps {
  /** Page on which catalogue is initially opened. */
  initialPageNumber: number | null;
}

export const PageNumberProvider: FC<PropsWithChildren<PageNumberProviderProps>> = ({ children, initialPageNumber }) => {
  const [initialPage, setInitialPage] = useState<number>(initialPageNumber ?? 1);

  const [currentPages, setCurrentPages] = useState<PageNumberContextValues['currentPages']>([initialPage]);
  const [debouncedCurrentPages] = useDebounce(currentPages, DEBOUNCE_DELAY);
  const [currentPagesInitialized, setCurrentPagesInitialized] = useState<boolean>(false);

  const { totalPageCount } = useGetCatalogueStructure();

  const changePageNumber = useCallback(
    (input: number | string) => {
      if (input === '') {
        return;
      }

      const page = transformPageInput(input, totalPageCount);

      const ipaperApiInstance = getApiInstance();
      if (ipaperApiInstance) {
        ipaperApiInstance.paging.goToPage(page);
      }
    },
    [totalPageCount],
  );

  useLayoutEffect(() => {
    setInitialPage(getPageNumberParamFromUrl());
  }, []);

  useEffect(() => {
    if (totalPageCount !== 0) {
      const currentPage = transformPageInput(initialPage, totalPageCount);

      setCurrentPages([currentPage]);
    }
  }, [initialPage, totalPageCount]);

  useEffect(() => {
    const currentPage = debouncedCurrentPages[0];
    if (currentPage != null) {
      updatePageNumberUrlParam(currentPage);
    }
  }, [debouncedCurrentPages]);

  const value = useMemo(
    () => ({
      changePageNumber,
      currentPages,
      initialPage,
      totalPageCount,
      setCurrentPages,
      currentPagesInitialized,
      setCurrentPagesInitialized,
    }),
    [changePageNumber, currentPages, initialPage, totalPageCount, currentPagesInitialized],
  );

  return <PageNumberContext.Provider value={value}>{children}</PageNumberContext.Provider>;
};
