import type { LoadedLibs, LoadedSpas } from '@ori-appshell/app-loaders';
import { useAppLoaders } from '@ori-appshell/app-loaders';
import { useFeatureToggles } from '@ori-appshell/config';
import { useToken } from '@ori/auth';
import { useServiceUrls } from '@ori/configuration-manager';
import { useEnvironment } from '@ori/environment';
import { useLanguage } from '@ori/i18n';
import { useAnalyticsSignalEventListener } from '@ori/presentation-http';
import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { PageFeatures } from '~/common-data/PageFeatures';

export const LayoutContentLoader = ({
  useOpenedMinibagOnDesktop = false,
  children,
}: PropsWithChildren<{ useOpenedMinibagOnDesktop?: boolean }>) => {
  const { setAppLoaders } = useAppLoaders();
  const { globalApiUrl } = useServiceUrls(['globalApi']);
  const { features } = useFeatureToggles();
  const { tenant } = useEnvironment();
  const { language } = useLanguage();
  const { isAnonymousUser, token, customerId } = useToken();

  useAnalyticsSignalEventListener({
    language,
    token,
    tenant,
    globalApiUrl,
    customerId,
    enabled: features.includes(PageFeatures.SendSearchEvents),
  });

  const libs = useMemo(
    () =>
      ({
        backInStockNotification: { enabled: !isAnonymousUser },
        basketBubble: { enabled: true },
        basketProvider: { enabled: true },
        favoritesProvider: { enabled: !isAnonymousUser },
        favoritesSubmenu: { enabled: !isAnonymousUser },
        feedbackBenefitDialog: { enabled: true },
        loyaltyDialog: { enabled: !isAnonymousUser },
        miniShoppingBag: { enabled: true, useOpenedMinibagOnDesktop },
        novageSkincareAdvisor: { enabled: true },
        productInfoDialog: { enabled: true },
        subscriptionWizard: { enabled: true },
      }) satisfies LoadedLibs,
    [isAnonymousUser, useOpenedMinibagOnDesktop],
  );

  const spas = useMemo(
    () =>
      ({
        logoutConfirmDialog: { enabled: !isAnonymousUser },
        userSessionProvider: { enabled: !isAnonymousUser },
      }) satisfies LoadedSpas,
    [isAnonymousUser],
  );

  // Client one
  useEffect(() => {
    setAppLoaders({ libs, spas });
  }, [setAppLoaders, spas, libs]);

  // eslint-disable-next-line react/jsx-no-useless-fragment -- TODO -- fix this
  return <>{children}</>;
};
