import * as OpenFeedbackBenefitDialog from '@ori-events/open-feedback-benefit-dialog';
import { ShareButton as ButtonShare } from '@ori/presentation-components';
import type { FC } from 'react';
import { memo } from 'react';

import { useCatalogueUrlAndId, usePageNumberContext } from '../../hooks';
import { useTranslations } from '../../hooks/useTranslations';
import { FeedbackBenefitBannerType } from '../../models/graphql-types/net';

const openFeedbackBenefitDialog = (detail: OpenFeedbackBenefitDialog.Data) => () => {
  window.dispatchEvent(
    new CustomEvent<OpenFeedbackBenefitDialog.Data>(OpenFeedbackBenefitDialog.Name, {
      detail,
    }),
  );
};

export const ShareButton: FC = memo(() => {
  const { translations } = useTranslations();
  const { currentPages } = usePageNumberContext();
  const cataloguePageNumber = currentPages[0] ?? 1;
  const { catalogueId } = useCatalogueUrlAndId();

  return (
    <ButtonShare
      aria-label={translations.shareTheCatalogue}
      onClick={openFeedbackBenefitDialog({
        type: FeedbackBenefitBannerType.ShareAndEarn,
        cataloguePageNumber,
        catalogueId,
      })}
    />
  );
});
